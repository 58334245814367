<template>
    <label class="--line --line--dark">
      <div class="field-wrap">
        <input :id="id" :name="name" type="radio" @input="input">
        <div class="field-dot"></div>
        <div class="field-label">{{ answer }}</div>
      </div>
    </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },

    externalLink: {
      type: String,
    },
  },

  methods: {
    input() {
      this.$emit('input', { answerId: this.id, answer: this.answer });

      if (this.$attrs.next) {
        this.$emit('next', this.$attrs.next);
      }

      if (this.externalLink) {
        this.$emit('externalLink', this.externalLink);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    padding: 20px 0;
    margin-top: -2px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-image: radial-gradient(circle, rgba(109, 82, 59, 0.5) 0%, rgba(109, 82, 59, 0) 100%);
      background-size: 85% calc(100% - 2px);
      background-position: 50% calc(50% - 2px);

      .field-label {
        color: $white;
      }
    }
  }

  .field-wrap {
    display: flex;
    align-items: center;

   .field-dot {
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/images/png/radio-inactive.png');
    background-size: contain;
    background-position: center;
    margin: 0 15px 0 -3px;
   }

   .field-label {
    color: $orange;
    font-family: 'QuadraatOffcPro Regular';
   }
  }

  input {
    opacity: 0;
    visibility: hidden;
    display: none;

    &:checked ~ .field-dot {
      background-image: url('~@/assets/images/png/radio-active.png');
    }

    &:checked ~ .field-label {
      color: $white;
    }
  }
</style>
