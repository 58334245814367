<template>
  <div>
    <div class="header-image" :class="`header-image--logo-${logoPosition}`">
      <div class="header-image__image" :style="{ backgroundImage: `url(${getImage})` }"></div>
      <img src="~@/assets/images/png/logo.png" class="header-image__logo" alt="Baldur's Gate 3 logo">
    </div>
    <div class="step">
      <span class="--decor">{{ page.label }}</span>
      <h1 class="step__title --text-gradient --line">{{ page.title }}</h1>
      <Question v-for="question in page.questions" :key="question.id" :question="question" @answer="addAnswer" />
    </div>
  </div>
</template>

<script>
import Question from './form/Question.vue';

export default {
  components: {
    Question,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },

    logoPosition: {
      type: String,
      default: 'left',
    },
  },

  computed: {
    getImage() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const image = require(`@/assets/images/jpg/${this.page.image}`);
      return image;
    },
  },

  methods: {
    addAnswer(data) {
      this.$emit('answer', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.step {
  text-align:left;
  width: 100%;
  margin-top: -50px;
  position: relative;
  z-index: 1;
}

fieldset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  text-align: left;;
}

.header-image {
  width: 100%;
  height: 270px;
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    // transform: translate3d(-50%, 0, 0);
    transform: translateX(-50%);
    width: 780px;
    height: 100%;
    background-size: cover;
    background-position: top center;

    mask-image: url('~@/assets/images/png/top-image-mask.png');
    mask-size: 100% 100%;
    mask-position: top center;
    mask-repeat: no-repeat;
  }

  &--logo-left {
    text-align: center;

    .header-image__logo {
      top: 10px;
      left: -75px;
    }
  }

  &--logo-center {
    .header-image__logo {
      top: 76px;
    }
  }

  &__logo {
    width: 190px;
    position: relative;
    z-index: 1;
  }
}

@media screen and (min-width: $screen-desktop) {
  .step {
    margin-top: -80px;
  }
  .header-image {
    height: 464px;

    &__image {
      width: 1400px;
    }

    &--logo-left {
      text-align: left;

      .header-image__logo {
        left: -65px;
      }
    }

    &--logo-center {
      .header-image__logo {
        top: 130px;
      }
    }

    &__logo {
      width: 368px;
    }
  }
}
</style>
