<template>
    <label class="--line --line--dark">
      <div class="field-wrap">
        <input :id="id" :name="name" type="radio" class="field-hidden" ref="input" @input="input">
        <div class="field-dot"></div>
        <div class="field-label">{{ answer }}</div>
        <input type="text" :name="name" class="field-answer" placeholder="Write your answer..." @input="input" @focus="onFocus">
      </div>
    </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      value: null,
    };
  },

  methods: {
    onFocus() {
      this.$refs.input.checked = true;
      this.value = 'Other';
      this.$emit('input', { answerId: this.id, answer: this.value });
    },

    input(e) {
      this.value = `Other: ${e.target.value}`;
      this.$emit('input', { answerId: this.id, answer: this.value });
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    padding: 15px 0;
    margin-top: -2px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-image: radial-gradient(circle, rgba(109, 82, 59, 0.5) 0%, rgba(109, 82, 59, 0) 100%);
      background-size: 85% calc(100% - 2px);
      background-position: 50% calc(50% - 2px);

      .field-label {
        color: $white;
      }
    }
  }

  .field-wrap {
    display: flex;
    align-items: center;

   .field-dot {
    width: 26px;
    height: 26px;
    background-image: url('~@/assets/images/png/radio-inactive.png');
    background-size: contain;
    background-position: center;
    margin-right: 15px;
   }

   .field-label {
    color: $orange;
    font-family: 'QuadraatOffcPro Regular';
   }
  }

  input.field-hidden {
    opacity: 0;
    visibility: hidden;
    display: none;

    &:checked ~ .field-dot {
      background-image: url('~@/assets/images/png/radio-active.png');
    }

    &:checked ~ .field-label {
      color: $white;
    }
  }

  .field-answer {
    margin-left: 20px;
  }
</style>
