<template>
    <label class="--line --line--dark"><input :id="id" :name="name" type="text" placeholder="Your answer..." @input="input">{{ answer }}</label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },

  methods: {
    input(e) {
      this.$emit('input', { answerId: this.id, answer: e.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    padding: 15px 0;
    width: 100%;
  }
</style>
