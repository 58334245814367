<template>
    <button class="button-simple navigation__back" @click="$emit('back')">
        <span class="button-simple__label">Back</span>
        <svg version="1.1" id="BTN-BACK"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 140 46" style="enable-background:new 0 0 140 46;" xml:space="preserve">
            <g id="btn-normal">
                <path id="background_1_" opacity="0.65" d="M139.951,0v16.336L123.618,0H139.951z M117.583,46H74.041l-4.003-4.003L66.036,46H22.775
                L0,23.804V22.41L22.775,0h43.199l4.032,4.033L74.038,0h43.545L140,22.419v1.394L117.583,46z M0,0h16.333L0,16.336V0z M0,46V29.665
                L16.333,46H0z M139.951,46h-16.333l16.333-16.104V46z"/>
                <g id="outline_1_">
                <path fill="#B99B77" d="M138.951,1v12.921L126.032,1H138.951 M117.169,1L139,22.833v0.563L117.172,45H74.456l-3.71-3.71l-0.707-0.707
                l-0.707,0.707L65.622,45h-42.44L1,23.383v-0.553L23.184,1H65.56l3.739,3.74l0.707,0.707l0.707-0.707L74.452,1H117.169 M13.919,1
                L1,13.921V1H13.919 M1,32.079L13.919,45H1V32.079 M138.951,32.286V45h-12.895L138.951,32.286 M139.951,0h-16.333l16.333,16.336V0
                L139.951,0z M117.583,0H74.038l-4.032,4.033L65.974,0H22.775L0,22.41v1.394L22.775,46h43.261l4.003-4.003L74.041,46h43.542
                L140,23.813v-1.394L117.583,0L117.583,0z M16.333,0H0v16.336L16.333,0L16.333,0z M0,29.665V46h16.333L0,29.665L0,29.665z
                M139.951,29.896L123.618,46h16.333V29.896L139.951,29.896z"/>
                </g>
            </g>
            <g id="btn-hover">
                <path id="background_2_" opacity="0.65" d="M139.951,0v16.336L123.618,0H139.951z M117.583,46H74.041l-4.003-4.003L66.036,46H22.775
                L0,23.804V22.41L22.775,0h43.199l4.032,4.033L74.038,0h43.545L140,22.419v1.394L117.583,46z M0,0h16.333L0,16.336V0z M0,46V29.665
                L16.333,46H0z M139.951,46h-16.333l16.333-16.104V46z"/>
                <g id="light" opacity="0.35">
                <g>
                    <path fill="#B99B77" d="M139.951,0v16.336L123.618,0H139.951z M117.583,46H74.041l-4.003-4.003L66.036,46H22.775L0,23.804V22.41
                    L22.775,0h43.199l4.032,4.033L74.038,0h43.545L140,22.419v1.394L117.583,46z M0,0h16.333L0,16.336V0z M0,46V29.665L16.333,46H0z
                    M139.951,46h-16.333l16.333-16.104V46z"/>
                </g>
                <defs>
                    <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="140" height="46">
                    <feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" color-interpolation-filters="sRGB" result="source"/>
                    </filter>
                </defs>
                <mask maskUnits="userSpaceOnUse" x="0" y="0" width="140" height="46" id="SVGID_1_">
                    <g filter="url(#Adobe_OpacityMaskFilter)">
                    <image style="overflow:visible;" width="144" height="50" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
            EAMCAwYAAAH6AAADFQAAA7v/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
            Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
            JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIADIAkAMBIgACEQEDEQH/
            xACEAAEAAgMBAQAAAAAAAAAAAAAABgcBAgUDBAEBAAAAAAAAAAAAAAAAAAAAABAAAgICAgEEAwAA
            AAAAAAAAAQIAAwQFEQYWMFAUFUASExEAAgEDBAEDBQAAAAAAAAAAAAExIUECEaEiMhAwURIgQGED
            IxIBAAAAAAAAAAAAAAAAAAAAUP/aAAwDAQACEQMRAAAAgB6mOr2pUQPayfQrLS0NCqFoZK52srcr
            LWz/ADKs5VsxUhj18jaURmXEu7fK65nIMZGGQAxkefD73HIJF5fEjz+z5BYUoqCRFk7QDcnmIJqT
            rFd6Fl5gG5PMQTQmMX5UdHx5GoAAAAAAAAP/2gAIAQIAAQUA9k//2gAIAQMAAQUA9k//2gAIAQEA
            AQUAVeYRxMfHsvanr72AdXczxV54q8bq7iZHX8hBj9fyLAvV3M8VeeKvD1dxLuvvWL8eyhgOYy8Q
            HiY+O+RZp9MAMTVKAmsTj62ufW1xtYhj6esxNRWsXWVifW1z62uPrE4y9UpG40wIyMezHsJ5lVZs
            fTYCCa3EUCutVHpWVqw2WIpG5wEMtrNb49ors0+ShmuuUqpBHpMQBsrlC7jKQTIt/o4HM1+e+NZq
            NsrDF2Csq5VZnyK58iuHJrEbNrEGdWYMqsz5Fc+RXGyqxMrYKq7jbqoz858mwjiIQI3BmBsLMZsb
            saoF7TWJ5XVPK6o3aqzMntQVcbtX7Be01ieV1TyuqN2mszJ7GrrnbCzJdeBHIP4f/9oACAECAgY/
            ABP/2gAIAQMCBj8AE//aAAgBAQEGPwDx8cVS7Naly5cufzTb9ma/sTT9kXLly5rU+OSpZ/QscYuz
            HiKhBBBB1OpBBBA+JlxHjkqWfhYoxoKnquhlQeLE3BjURT06jMqjajwk3xMeQqkkkkkkkkkkjqZc
            hpPj4qUEm+Itczvud9zvud9xvHLX8I1yfxfszvud9zvud9x6ZjWvEqU+z//Z" transform="matrix(1 0 0 1 -2 -2)"></image>
                    </g>
                </mask>
                <g mask="url(#SVGID_1_)">
                    <path class="st8" d="M139.951,0v16.336L123.618,0H139.951z M117.583,46H74.041l-4.003-4.003L66.036,46H22.775L0,23.804V22.41
                    L22.775,0h43.199l4.032,4.033L74.038,0h43.545L140,22.419v1.394L117.583,46z M0,0h16.333L0,16.336V0z M0,46V29.665L16.333,46H0z
                    M139.951,46h-16.333l16.333-16.104V46z"/>
                </g>
                </g>
                <g id="outline_2_" class="st9">
                <path fill="#FFFFFF" d="M138.951,1v12.921L126.032,1H138.951 M117.169,1L139,22.833v0.563L117.172,45H74.456l-3.71-3.71
                l-0.707-0.707l-0.707,0.707L65.622,45h-42.44L1,23.383v-0.553L23.184,1H65.56l3.739,3.74l0.707,0.707l0.707-0.707L74.452,1
                H117.169 M13.919,1L1,13.921V1H13.919 M1,32.079L13.919,45H1V32.079 M138.951,32.286V45h-12.895L138.951,32.286 M139.951,0
                h-16.333l16.333,16.336V0L139.951,0z M117.583,0H74.038l-4.032,4.033L65.974,0H22.775L0,22.41v1.394L22.775,46h43.261l4.003-4.003
                L74.041,46h43.542L140,23.813v-1.394L117.583,0L117.583,0z M16.333,0H0v16.336L16.333,0L16.333,0z M0,29.665V46h16.333L0,29.665
                L0,29.665z M139.951,29.896L123.618,46h16.333V29.896L139.951,29.896z"/>
                </g>
            </g>
        </svg>
    </button>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.button-simple {
  border: none;
  width: 100px;
  padding: 0;
  cursor: pointer;
  position: relative;

  &__label {
    font-family: 'QuadraatOffcPro Regular';
    text-transform: uppercase;
    color: $white;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  svg {
    fill: unset;

    #btn-hover {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    #btn-normal {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  &:hover {
    svg {
      #btn-hover {
        opacity: 1;
      }

      #btn-normal {
        opacity: 0;
      }
    }
  }
}

@media screen and (min-width: $screen-desktop) {
    .button-simple {
        width: 140px;

        &__label {
            font-size: 16px;
        }
    }
}
</style>
