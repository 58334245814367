<template>
    <div class="survey">
      <div class="container">
        <div class="survey__wrap">
          <transition name="fade" mode="out-in">
            <Page v-if="!surveySent && !surveyError" :page="pageData" :logoPosition="currentPage === 1 ? 'center' : 'left'" :key="currentPage" @answer="getAnswer"></Page>
            <ThankYou v-if="surveySent || surveyError" key="finish">
              <div v-if="surveySent">
                <div class="thank-you__icon thank-you__icon--check"></div>
                <h1 class="thank-you__title --line --text-gradient">Thank you for completing our survey</h1>
                <p>We value your feedback and thank you for playing Baldur's Gate 3</p>
              </div>
              <div v-if="surveyError">
                <div class="thank-you__icon thank-you__icon--error"></div>
                <h1 class="thank-you__title --line --text-gradient">Error, we were unable to send the survey</h1>
                <p>Please check the internet connection</p>
              </div>
            </ThankYou>
          </transition>
        </div>
        <transition name="fade-nav" mode="out-in">
          <div class="navigation" v-if="currentPage != 1 && !surveySent && !surveyError" key="nav">
            <btnBack @back="back"/>
            <btnSubmit @submit="submit" />
          </div>
        </transition>
        <btnSubmit v-if="currentPage === 0" @submit="submit" />
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import jump from 'jump.js';
import Page from './Page.vue';
import ThankYou from './ThankYou.vue';
import btnBack from './buttons/btnBack.vue';
import btnSubmit from './buttons/btnSubmit.vue';
import EventBus from '../plugins/event-bus';

export default {
  components: {
    Page,
    ThankYou,
    btnBack,
    btnSubmit,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      formAnswers: {},
      externalLink: null,
      surveySent: false,
      surveyError: false,
    };
  },

  computed: {
    pageData() {
      return this.form.pages.find((x) => x.id === this.currentPage);
    },
  },

  methods: {
    getAnswer(data) {
      this.formAnswers[data.questionId] = data;
    },

    back() {
      this.scrollTopTop();
      this.currentPage = 1;
      this.formAnswers = {};
    },

    submit() {
      if (this.externalLink) {
        window.open(this.externalLink, '_blank');
      }
      axios.post(`${process.env.VUE_APP_API_URL}api/surveys/submit/${process.env.VUE_APP_SURVEY_NAME}`, { survey: this.formAnswers })
        .then((res) => {
          if (res.status === 200) {
            this.surveySent = true;
          } else {
            this.surveyError = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.surveyError = true;
        });
    },

    scrollTopTop() {
      jump('.survey', {
        duration: 500,
      });
    },
  },

  mounted() {
    EventBus.$on('changePage', (pageId) => {
      this.scrollTopTop();
      this.currentPage = pageId;
    });

    EventBus.$on('openExternalLinkOnSubmit', (link) => {
      this.externalLink = link;
    });
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: all 0.4s;
}
.fade-enter {
  opacity: 0;
  margin-left: 90px;
}
.fade-leave-active {
  transition: all 0.4s;
  opacity: 0;
  margin-left: -100px;
}

.fade-nav-enter-active {
  transition: all 0.4s;
  transition-delay: 0.4s;
}
.fade-nav-enter {
  opacity: 0;
  margin-left: 90px;
}
.fade-nav-leave-active {
  transition: all 0.4s;
  opacity: 0;
  margin-left: -100px;
}

.survey {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    overflow: hidden;

    &__wrap {
      position: relative;
    }
}

.field-wrap {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.navigation {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: $screen-desktop) {
.survey {
  margin-bottom: 150px;
}
}
</style>
