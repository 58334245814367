<template>
    <fieldset>
      <legend>{{ question.question }}</legend>
      <component v-for="answer in question.answers" :key="answer.id" :is="answer.type" v-bind="answer" @input="input" @next="nextPage" @checkboxAdd="checkboxAdd" @checkboxRemove="checkboxRemove" @externalLink="openExternalLinkOnSubmit"></component>
    </fieldset>
</template>

<script>
import radioField from './radioField.vue';
import checkboxField from './checkboxField.vue';
import textField from './textField.vue';
import textareaField from './textareaField.vue';
import radioInputField from './radioInputField.vue';
import buttonField from './buttonField.vue';

import EventBus from '../../plugins/event-bus';

export default {
  components: {
    radioField,
    checkboxField,
    textField,
    textareaField,
    radioInputField,
    buttonField,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      questionData: { question: this.question.question, questionId: this.question.id },
      answers: { answers: [] },
    };
  },

  methods: {
    input(data) {
      const mergedData = { answers: [{ ...data }], ...this.questionData };
      this.$emit('answer', mergedData);
    },

    checkboxAdd(data) {
      this.answers.answers.push(data);
      const mergedData = { ...this.questionData, ...this.answers };
      this.$emit('answer', mergedData);
    },

    checkboxRemove(id) {
      this.answers.answers = this.answers.answers.filter((item) => item.answerId !== id);
      const mergedData = { ...this.questionData, ...this.answers };
      this.$emit('answer', mergedData);
    },

    nextPage(pageId) {
      EventBus.$emit('changePage', pageId);
    },

    openExternalLinkOnSubmit(link) {
      EventBus.$emit('openExternalLinkOnSubmit', link);
    },
  },
};
</script>

<style lang="scss" scoped>
  fieldset {
    padding: 0;
    margin-top: 30px;
  }

  legend {
    font-family: 'QuadraatOffcPro Demibold';
    color: $white;
    font-size: 22px;
  }

  @media screen and (min-width: $screen-desktop) {
    fieldset {
      margin-top: 40px;
    }

    legend {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
</style>
