<template>
  <div id="app">
    <Survey v-if="surveyConfig" :form="surveyConfig" />
    <smoke />
  </div>
</template>

<script>
import axios from 'axios';
import Survey from './components/Survey.vue';
import smoke from './components/smoke.vue';

export default {
  name: 'App',
  components: {
    Survey,
    smoke,
  },

  data() {
    return {
      surveyConfig: null,
    };
  },

  created() {
    axios.get(`${process.env.VUE_APP_API_URL}api/surveys/code/${process.env.VUE_APP_SURVEY_NAME}`)
      .then((res) => {
        this.surveyConfig = JSON.parse(res.data.config);
      });
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
