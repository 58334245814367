<template>
  <label class="--line --line--dark">
    <textarea :id="id" :name="name" rows="1" placeholder="Your answer..." @input="input"></textarea>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },

  methods: {
    input(e) {
      this.$emit('input', { answerId: this.id, answer: e.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    width: 100%;
  }
</style>
