<template>
    <div class="thank-you">
        <frame />
        <slot></slot>
    </div>
</template>

<script>
import frame from './ui/frame.vue';

export default {
  components: {
    frame,
  },
};
</script>

<style lang="scss" scoped>
.thank-you {
    padding: 40px;
    margin-top: 120px;
    text-align: center;

    &__icon {
        width: 66px;
        height: 66px;
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;

        &--check {
            background-image: url('~@/assets/images/png/check.png');
        }

        &--error {
            background-image: url('~@/assets/images/png/error.png');
        }
    }

    &__title {
        font-size: 26px;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        color: $white-65;
        font-size: 18px;
        font-family: 'QuadraatOffcPro Regular';
    }
}

@media screen and (min-width: $screen-desktop) {
    .thank-you {
        min-width:780px;
        padding: 60px;

        &__icon {
            width: 74px;
            height: 74px;
        }

        &__title {
            font-size: 38px;
        }

        p {
            font-size: 20px;
        }
    }
}
</style>
